import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import locale from "element-plus/es/locale/lang/zh-cn";
import "@/styles/index.css";


const app = createApp(App);

app.use(router)   .use(ElementPlus, { locale });


app.mount('#app');
