/*
* 用户权限相关公用函数
* */
import LocalStorage from "localStorage";

const UserKey = "User-Token";

export function getProductInfo() {
    let productInfo = LocalStorage.getItem(UserKey);
    if (productInfo) productInfo = JSON.parse(productInfo);
    return productInfo;
}

export function setProductInfo(productInfo) {
    return LocalStorage.setItem(UserKey, JSON.stringify(productInfo));
}

export function removeProductInfo() {
    return LocalStorage.removeItem(UserKey);
}
