import router from '../router';
export function jumpPage(url, params) {
    let link = "/";
    if (url) link += url;
    router.push({ path: link, query: params });
}

/**
 * 判断当前是否为移动设备
 * @returns {boolean} 如果是移动设备返回 true，否则返回 false
 */
export function isMobileDevice() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // 常见的移动设备标识
    const mobileDeviceRegex = /android|avantgo|blackberry|iemobile|ipad|iphone|ipod|opera mini|palm|plucker|pocket|psp|series60|symbian|windows ce|windows phone|xiino/i;

    return mobileDeviceRegex.test(userAgent);
}
