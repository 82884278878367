<template>
    <div class="app-wrapper">
        <component :is="viewType" />
    </div>
</template>

<script>
import { isMobileDevice } from '../utils/public';
import DesktopView from "./DesktopView.vue"; //功能页面
import MoblieView from "./MoblieView.vue"; //功能页面

export default {
    name: "index",
    //建立父子联系
    components: { DesktopView, MoblieView },
    data() {
        return {
            viewType: "DesktopView"
        };
    },
    mounted() {
        let userAgentInfo = navigator.userAgent;
        let isMobile = userAgentInfo.indexOf("Windows") < 0;
        console.log(isMobile);
        let dd = this;
        if (isMobileDevice()) {
            dd.viewType = "MoblieView";
        }
    },
    methods: {
        test() {
        }
    } /*,
    mixins: [ResizeHandle]*/
};
</script>

<style scoped>
.app-wrapper {
    width: 100%;
}
</style>
