<template>
  <div>
    <div style="position: relative;">
      <img alt="Vue logo" src="https://qiniu.shunmingelectronic.com/guanwang/product/homebackground.png"
        style="width: 100%; height: auto; opacity: 1;">
      <!-- <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgb(110 110 110 / 50%);"></div> -->
      <div class="home-page-text">
        <div style="font-size: 50px; margin-top: 10px;">Precise measurement, trusted assurance</div>
        <div style="font-size: 30px; margin-top: 20px;">we provide the most reliable instrumentation to support every
          step of industrial automation
        </div>
        <!-- <div style="padding: 20%;font-size: 25px;text-align: center;">We focus on the technical development, technical
          services, and instrume-ntation manufacturing and production of flow meters. The business scope of
          electromagnetic flowmeters includes the R&D and production of converters, PCB, Vortex flowmeters, Turbine
          flowmeters and gas flowmeters, etc.
        </div> -->
      </div>
    </div>
    <!-- 我们的服务展示区域 -->
    <div style="margin-top: 50px;;margin-bottom: 110px;">
      <div style="font-size: 30px;font-weight: 700;font-family: math;">We offer the following services</div>
      <div style="margin-top: 30px; display: flex; justify-content: center; gap: 140px; margin-bottom: 50px;">
        <div class="following-services-area">
          <div
            style="display: flex; align-items: center; justify-content: center; height: 50%; font-size: 22px; font-weight: 600; color: white;">
            <img src="../assets/meterFlow.png" alt="SVG Image" width="70" height="70" />
          </div>
          <div style="height: 50%; font-size: 22px; font-weight: 600; color: white;">
            High-precision measurement and stable operation
          </div>
        </div>
        <div class="following-services-area">
          <div
            style="display: flex; align-items: center; justify-content: center; height: 50%; font-size: 22px; font-weight: 600; color: white;">
            <img src="../assets/platform.png" alt="SVG Image" width="65" height="65" />
          </div>
          <div style="height: 50%; font-size: 22px; font-weight: 600; color: white;">
            The monitored data is uploaded to the IoT platform in real-time
          </div>
        </div>
        <div class="following-services-area">
          <div
            style="display: flex; align-items: center; justify-content: center; height: 50%; font-size: 22px; font-weight: 600; color: white;">
            <img src="../assets/service.png" alt="SVG Image" width="70" height="70" />
          </div>
          <div style="height: 50%; font-size: 22px; font-weight: 600; color: white;">
            High-quality after-sales service guarantee
          </div>
        </div>
      </div>
    </div>
    <!-- 产品图片区域 -->
    <div style="background-color: #fff;padding-bottom: 40px;">
      <div class="area-title">Products</div>
      <div style="margin-top: 50px; display: flex; justify-content: center; gap: 60px; margin-bottom: 50px;">

        <div v-for="(product, index) in categoryList" :key="index" class="product-image-style" @click="handClick('products',{categoryId:product.categoryId})">
          <img :src="product.image" alt="SVG Image" width="95%" height="95%" class="product-image-size-style">
          <div class="product-name-style">{{ product.categoryEnName }}</div>
        </div>
      </div>
    </div>
    <div>
      <div class="area-title">IOT Platform</div>
      <div><img src="https://qiniu.shunmingelectronic.com/guanwang/platform.jpg" /></div>
      <div style="font-size: 28px;margin-bottom: 20px;color: #7f7f7f;">What Can an IoT Platform Offer You?</div>
      <div style="">
        <div class="iot-description-style">
          <div style="margin-right: 50px;">●</div>
          <div style=" color: #7f7f7f; width: 60%;"> The Shunming Intelligent Control IoT Platform utilizes 4G technology in
            combination with wireless
            long-distance
            electromagnetic flowmeters, allowing users to view real-time flow and cumulative flow data, as well as
            remotely
            configure device parameters via a web browser or mobile application.</div>
        </div>
        <div class="iot-description-style">
          <div style="margin-right: 50px;">●</div>
          <div style=" color: #7f7f7f; width: 60%;"> 
            We can provide platform access permissions for distributors. Once authorized, distributors can allocate platform user accounts to their own end users, granting them access to the platform. Distributors can also set their own logo and system title, eliminating the need for additional development and reducing operational costs.</div>
        </div>
        <div style="padding: 50px 300px;" ><span class="button-style" @click="handClick('iot')" >view more</span></div>
      </div>
    </div>
  </div>
</template>

<script>
import { jumpPage } from '@/utils/public';
import { getProductInfo } from '../utils/auth';
export default {
  name: 'HomeView',
  data() {
    return {
      productList:
        [
          { imgUrl: "/assets/meterFlow.jpg", productname: 'Electromagnetic Flowmeter' },
          { imgUrl: "/assets/wojie.png", productname: 'Vortex Flowmeter' },
          { imgUrl: "/assets/csb.png", productname: 'Ultrasonic Flowmeter' },
          { imgUrl: "/assets/wolun.png", productname: 'Turbine Flowmeter' }
        ],
      categoryList: []
    }
  },
  methods:{
    handClick(val,query){
      jumpPage(val,query);
    }
  },
  mounted() {
    this.categoryList = getProductInfo();
    console.log(this.categoryList);
  }
}
</script>

<style scoped>
.home-page-text {
  position: absolute;
  top: 40%;
  width: 100%;
  color: white;
  font-size: 36px;
  font-weight: bold;
  text-align: center
}

.area-title {
  font-size: 30px;
  font-weight: 700;
  font-family: math;
  margin-top: 80px;
  margin-bottom: 20px;
}

.following-services-area {
  width: 280px;
  height: 280px;
  background: #1f85ff;
  border-radius: 180px;
  cursor: pointer;

  &:hover {
    background: #96c6ff;
  }
}

.product-image-style {
  cursor: pointer;
  width: 250px;
  height: 250px;

  .product-image-size-style {
    &:hover {
      width: 100%;
      height: 100%;
    }
  }

  .product-name-style {
    font-size: 28px;
    color: #17abab;
    font-family: math;
  }
}

.iot-description-style {
  display: flex;
  justify-content: center;
  text-align: left;
  padding: 30px;
  font-size: 22px;
  font-family: math;

}
</style>
