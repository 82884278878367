<template>
    <div class="about-view-style">
        <div style="position: relative; margin-bottom: 180px;">
            <div><img style="width: 100%;"
                    src="https://qiniu.shunmingelectronic.com/guanwang/product/%E5%85%B3%E4%BA%8E%E6%88%91%E4%BB%AC.png" />
            </div>
            <div class="home-page-text">
                <div style="padding: 0px 20%;   color: #09bfff;;">
                    About Us
                </div>
            </div>
        </div>
        <div class="about-title">About ShunMing</div>
        <div class="container">
            <div class="left">
                <img style="width: 100%;" src="https://qiniu.shunmingelectronic.com/guanwang/product/huiyi.jpg" />
            </div>
            <div class="right">
                <div style=" margin-top: 150px; font-size: 30px; color: #1296db; font-family: math;text-align: left;">
                    Henan Shunming specializes in the technical development, technical services, and manufacturing of
                    flow meters. The business scope of electromagnetic flow meters includes the research and production
                    of converters, PCBs, vortex flow meters, turbine flow meters, and gas flow meters.
                </div>
            </div>
        </div>
        <div class="container">
            <div class="left">
                <div style=" margin-top: 150px; font-size: 30px; color: #1296db; font-family: math;text-align: left;">
                    Addition to these, we integrate advanced 4G technology into our instruments, enabling seamless data
                    transmission to IoT platforms. This allows for real-time monitoring and management, ensuring our
                    customers benefit from enhanced connectivity and operational efficiency.
                </div>

            </div>
            <div class="right">
                <img style="width: 100%;" src="https://qiniu.shunmingelectronic.com/guanwang/platform.jpg" />
            </div>
        </div>
        <div class="about-title">Why Choose Us</div>
        <div class="choose-style">
            <div><img src="../assets/chuanx.png" class="image-style"></div>
            <div class="choose-text">Innovation : We continuously innovate by integrating 4G technology into our
                instruments, enabling seamless connectivity to IoT platforms and offering smart solutions.</div>
        </div>
        <div class="choose-style">
            <div><img src="../assets/zhuanye.png" class="image-style"></div>
            <div class="choose-text">Expertise : We have years of experience in the research and production of flow
                meters, delivering high-quality products and solutions.</div>
        </div>

        <div class="choose-style">
            <div><img src="../assets/fuwu.png" class="image-style"></div>
            <div class="choose-text">Quality Service : We provide not just products but also comprehensive technical
                support and after-sales service, ensuring a worry-free experience for our customers.</div>
        </div>
        <div class="choose-style">
            <div><img src="../assets/kehu.png" class="image-style"></div>
            <div class="choose-text">Customer-Centric : We prioritize customer needs, offering customized solutions to help achieve greater operational efficiency.</div>
        </div>

        <div class="about-title">R&D environment</div>
        <div style="padding: 10%; display: flex; flex-wrap: wrap; justify-content: space-between;">
            <img style="width: 30%; margin-bottom: 40px;"
                src="https://qiniu.shunmingelectronic.com/guanwang/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20240806142917.jpg" />
            <img style="width: 30%; margin-bottom: 40px;"
                src="https://qiniu.shunmingelectronic.com/guanwang/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20240806142924.jpg" />
            <img style="width: 30%; margin-bottom: 40px;"
                src="https://qiniu.shunmingelectronic.com/guanwang/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20240806142932.jpg" />
            <img style="width: 30%; margin-bottom: 40px;"
                src="https://qiniu.shunmingelectronic.com/guanwang/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20240806142941.jpg" />
            <img style="width: 30%; margin-bottom: 40px;"
                src="https://qiniu.shunmingelectronic.com/guanwang/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20240806142948.jpg" />
            <img style="width: 30%; margin-bottom: 40px;"
                src="https://qiniu.shunmingelectronic.com/guanwang/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20240806142953.jpg" />
        </div>
    </div>
</template>
<script></script>
<style scoped>
.about-view-style {
    .about-title {
        font-size: 60px;
        margin-top: 160px;
        font-family: initial;
        font-weight: bold;
        color: #1296db;
    }

    .home-page-text {
        position: absolute;
        top: 40%;
        width: 100%;
        color: white;
        font-size: 50px;
        font-weight: bold;
        text-align: center
    }

    .container {
        display: flex;
        margin: 100px 10%;
        height: 600px;
        background-color: #F1F1F1;
    }

    .left {
        width: 50%;
        background-color: white;
        /* 用于演示 */
    }

    .right {
        padding-left: 150px;
        width: 50%;
        background-color: white;
    }

    .choose-style {
        text-align: left;
        height: 150px;
        margin: 80px 250px 80px 300px;
        display: flex;

        /* 启用 flexbox 布局 */
        .image-style {
            width: auto;
            height: 100%;
        }

        .choose-text {
            align-items: center;
            font-size: 30px;
            display: flex;
            margin-left: 100px;
            color: #1296db;

        }

    }
}
</style>