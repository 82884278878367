<template>
  <div>
    <div>
      <img style="width: 100%;" src="https://qiniu.shunmingelectronic.com/guanwang/product/lianxiwomen.jpg" />
    </div>
    <div class="home-page-text">
      <div style="padding: 0px 20%;   color: #09bfff;">Contact Us</div>
    </div>
    <div style="display: flex; padding: 50px 15%;">
      <div style="margin-bottom: 80px; color: rgb(134 134 134);width: 50%; text-align: center;">
        <div style="font-size: 20px;margin-top: 20px; font-weight: 700;text-align: left;">
          <div style="margin-bottom: 10px;color: rgb(94 196 231);">Company Name:</div> <span>Henan Shunming Electronic
            Information Technology Co., Ltd.</span>
        </div>
        <div style="font-size: 20px;margin-top: 40px; font-weight: 700;text-align: left;">
          <div style="margin-bottom: 10px;color: rgb(94 196 231);">Company Address:</div> <span>No. 1 Fenghua Road,
            High-Tech Development Zone, Luoyang Area, China (Henan) Pilot Free Trade Zone, Henan, China</span>
        </div>
        <div style="font-size: 20px;margin-top: 40px; font-weight: 700;text-align: left;">
          <div style="margin-bottom: 10px;color: rgb(94 196 231);">Contact Phone Number:</div> <span>
            +86 15038549758</span>
        </div>
        <div style="font-size: 20px;margin-top: 40px; font-weight: 700;text-align: left;">
          <div style="margin-bottom: 10px;color: rgb(94 196 231);">Email Us:</div> <span>
            2294742947@qq.com</span>
        </div>
      </div>
      <div style="width: 50%; display: flex; justify-content: right; margin-top: 20px;">
        <div style="width: 40%;">
          <div style="font-size: 20px;margin-bottom: 30px;">Wechat</div>
          <div><img src="../assets/weixin.jpg" width="90%" /></div>
        </div>
        <div style="width: 40%;">
          <div style="font-size: 20px;margin-bottom: 30px;">WhatsApp</div>
          <div><img src="../assets/whatapp.png" width="90%" /></div>
        </div>
      </div>
    </div>

    <div class="contact-container">
      <div class="contact-form">
        <div style="font-size: 3rem;color: #09bfff;font-family: A;margin-bottom: 30px">Inquiry</div>
        <el-form :model="form" ref="form">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item prop="name" required>
                <el-input v-model="form.name" style="width: 100% ;height: 40px;" placeholder="* Name" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="email" required>
                <el-input v-model="form.email" style="width: 100% ;height: 40px;" placeholder="* Email" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item prop="phone">
                <el-input v-model="form.phone" style="width: 100% ;height: 40px;" placeholder="Telephone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="company">
                <el-input v-model="form.company" style="width: 100% ;height: 40px;" placeholder="Company"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row> <el-col :span="24">
              <el-form-item prop="remarks" required>
                <el-input type="textarea" v-model="form.remarks" placeholder="Inquiry" :rows="5"></el-input>
              </el-form-item>
            </el-col></el-row>
          <!-- 提交按钮 -->
          <el-form-item>
            <el-button type="primary" @click="submitForm" style="width: 100%;height: 40px;font-size: 20px;">Send
              Message</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios';
export default {
  name: 'ContactView',
  data() {
    return {
      form: {
        name: '',
        email: '',
        phone: '',
        company: '',
        remarks: ''
      }
    };
  },
  methods: {
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          axios.post('website/website', { actionType: 'logInquiry', form: this.form })
            .then(response => {
              if (response.data.success) {
                alert('Send Success');
                this.$refs.form.resetFields();
              } else {
                alert(response.data.message);
              }
            })
            .catch(error => {
              alert('Error fetching data:', error);
            });
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.form.resetFields();
    }
  }
}
</script>
<style scoped>
.home-page-text {
  position: absolute;
  top: 15%;
  width: 100%;
  color: white;
  font-size: 50px;
  font-weight: bold;
  text-align: center
}

/* 父容器使用 flexbox 让表单居中 */
.contact-container {
  display: flex;
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  min-height: 60vh;
  /* 设置一个最小高度，确保在视口中居中 */
}

/* 表单样式 */
.contact-form {
  background-color: #f0f4f7;
  padding: 20px;
  width: 40%;
  border-radius: 5px;
  margin-bottom: 50px;
}

.el-input__inner {
  font-size: 30px;
}
</style>