<template>
  <div style="width: 100%;">
    <div style="position: relative;">
      <img alt="Vue logo" src="https://qiniu.shunmingelectronic.com/guanwang/product/productBanner.jpg"
        style="width: 100%; height: auto; opacity: 1;">
      <div class="home-page-text">
        <div style="font-size: 50px; margin-top: 10px;">Products</div>
      </div>
    </div>
    <div class="tabs">
      <div v-for="(product, index) in productInfo" :key="index" @click="selectTab(product.categoryId)" class="tab"
        :class="{ active: isActiveTab(product.categoryId) }">
        {{ product.categoryEnName }}</div>
    </div>
    <div class="tab-content">
      <div class="product-grid" >
        <div v-for="(product, index) in productList" :key="index" class="product-item" style="      box-sizing: border-box;
      text-align: center;
      border: #ffffff 2px solid;
      cursor: pointer;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
      /* 鼠标悬停时的边框阴影 */
      overflow: hidden;margin-bottom: 50px;"
          @click="jumpProductDetail(product)">
          <div style="overflow: hidden;">
            <img :src="product.image" :alt="product.productEnName" class="product-image"  style="width: 100%;height: auto;"/>
          </div>
          <div class="product-name" style=" font-size: 20px;
        background-color: #94c9f2;
        color: white;
        padding-top: 30px;
        height: 60px;
        font-family: math;">{{ product.productEnName }}</div>
        </div>
      </div>

      <!-- <div v-for="(product, index) in productList" :key="index" class="product-container">
          <div style="width: 300px;cursor: pointer;"> <img :src="product.image" alt="SVG Image" width="250px"
              height="250px" /></div>
          <div style="padding-top:5px;text-align: left;">
            <div style="font-size: 26px;">{{ product.productEnName }}</div>
            <div style="font-size: 18px;margin-top: 25px;color: #868686;"><span>Model :
              </span><span>{{ product.model }}</span></div>
            <div style="font-size: 18px;margin-top: 15px;color: #868686;"><span>Size : </span><span>{{
              product.specifications }}</span>
            </div>
            <div style="font-size: 18px;margin-top: 15px;color: #868686;height: 80px;"><span>{{ product.detail }}</span>
            </div>
            <div style="height: 40px;"> <span class="details-button">Details</span> </div>
          </div>
        </div> -->

    </div>
  </div>
</template>

<script>
import { getProductInfo } from '@/utils/auth';
export default {
  name: 'MobileProductsView',
  data() {
    return {
      activeTab: 1, // 初始化为第一个Tab
      productList: [],
      matchedProduct: null
    };
  },
  methods: {
    selectTab(index) {
      const matchedProduct = this.productInfo.find(product => product.categoryId === index);
      console.log(matchedProduct);
      console.log(matchedProduct);
      this.matchedProduct = matchedProduct;
      this.productList = matchedProduct.products;
      this.activeTab = index;
    },
    isActiveTab(index) {
      return this.activeTab === index;
    },
    jumpProductDetail(product) {
      let link = "/productsDetail";
      this.$router.push({ path: link, query: { productId: product.productId, productEnName: product.productEnName, categoryId: this.matchedProduct.categoryId, categoryEnName: this.matchedProduct.categoryEnName } });
    }
  },
  watch: {

  },
  mounted() {
    this.productInfo = getProductInfo();
    let initActiveTab = this.$route.query.categoryId ? this.$route.query.categoryId : this.productInfo[0].categoryId;
    this.selectTab(parseInt(initActiveTab));
    // if(this.$route.query.categoryId){
    //   this.selectTab(parseInt(this.$route.query.categoryId));
    // }else{
    //   this.selectTab(parseInt(this.productInfo[0].categoryId));
    // }

    //let me=this;
    //me.activeTab=2;
    // setTimeout(function(){
    //   me.activeTab=2;
    // },2000);
  },
  updated() {

  }
}
</script>

<style scoped>
.tabs {
  display: flex;
  justify-content: flex-start;
  /* 左对齐或根据需要调整 */
  overflow-x: auto;
  /* 启用横向滚动 */
  white-space: nowrap;
  /* 防止子元素换行 */
  margin-top: 20px;
  padding: 10px;
  /* 添加一些内边距，使滚动条不会遮挡内容 */

}

.tab {
  flex-shrink: 0;
  position: relative;
  padding: 15px;
  /* 给间隔符留出空间 */

}

.tab::after {
  content: "|";
  /* 间隔符 */
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #ccc;
  /* 间隔符的颜色，可以根据需求调整 */
}

.tab:last-child::after {
  content: "";
  /* 移除最后一个tab的间隔符 */
}

.tab:hover {}

.tab.active {
  border-bottom: 2px rgb(73, 109, 219) solid;
  /* 初始状态下隐藏底部边框 */
  color: rgb(73, 109, 219);
}

.tab-content {
  padding: 30px 15%;
  border: none;

  .product-container {
    padding: 30px 0px;
    border-bottom: 2px #b9c7ff solid;
    display: flex;
  }

  .product-grid {
    display: flex;
    flex-wrap: wrap;
  }
      /* 图片和名称之间的间距 */
      .product-item {
      box-sizing: border-box;
      text-align: center;
      border: #ffffff 2px solid;
      cursor: pointer;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
      /* 鼠标悬停时的边框阴影 */
      overflow: hidden;
      /* 防止放大的图片溢出容器 */

      .product-image {
        width: 100%;
        height: auto;
        object-fit: cover;
        transition: transform 0.3s ease;
        /* 添加平滑的过渡效果 */
      }

      .product-name {
        font-size: 20px;
        background-color: #94c9f2;
        color: white;
        padding-top: 30px;
        height: 60px;
        font-family: math;
      }

    }
}

.details-button {
  padding: 8px 40px;
  color: #fff;
  font-size: 20px;
  border-radius: 20px;
  background-color: #87a8ff;
  border: 2px #ffffff solid;
  cursor: pointer;

  &:hover {
    background-color: #ffffff;
    border: 2px #87a8ff solid;
    color: #87a8ff;
  }
}
</style>