<template>
    <div id="app">
        <!-- 顶部导航栏 -->
        <div class="top-container">
            <div style="display: flex; align-items: center;">
                <img src="../assets/logoc.png" alt="Company Logo" style="height: 50px; margin-left: 20px;">
                <span
                    style="font-size: 36px;font-family: fantasy;margin-left: 15px;color: #4bc3ff;letter-spacing: 1px">ShunMing</span>
            </div>
            <div style="height: 100%; display: flex; align-items: center;">
                <div class="nav-item" @mouseover="showDropdown('home')" @mouseleave="hideDropdown('home')">
                    <a href="#" class="nav-link" @click="handlerClick()">Home</a>
                </div>
                <div class="nav-item" @mouseover="showDropdown('about')" @mouseleave="hideDropdown('about')">
                    <a href="#" class="nav-link" @click="handlerClick('about')">About Us</a>
                </div>
                <div class="nav-item" @mouseover="showDropdown('products')" @mouseleave="hideDropdown('products')">
                    <a href="#" class="nav-link" @click="handlerClick('products')">Pruduct</a>
                    <div v-if="activeDropdown === 'products'" class="dropdown-content" style="width: 1980%;">
                        <a v-for="(product, index) in productInfo" :key="index" href="#"
                            @click="handlerClick('products', { categoryId: product.categoryId })">{{
                                product.categoryEnName }}</a>
                    </div>
                </div>
                <div class="nav-item" @mouseover="showDropdown('iot')" @mouseleave="hideDropdown('iot')">
                    <a href="#" class="nav-link" @click="handlerClick('iot')">Iot Platfrom</a>
                </div>
                <div class="nav-item" @mouseover="showDropdown('contact')" @mouseleave="hideDropdown('contact')">
                    <a href="#" class="nav-link" @click="handlerClick('contact')">Contact Us</a>
                </div>
            </div>
            <span
                style="text-align: right; margin-right: 30px; line-height: 80px; color: rgb(185, 210, 219); font-size: 30px; font-family: fangsong; font-weight: 900;">Shunming
                International


            </span>
        </div>

        <!-- 路由视图 -->
        <div class="router-view-container">
            <router-view></router-view>
        </div>
        <div style="background-color: #000000;">
            <div style="color: rgb(71 71 71);  padding-bottom: 50px 0px;">
                <div
                    style="font-size: 25px;padding-top: 30px;margin-bottom: 80px;text-align: center;width: 100%;color: #daf4ff;">
                    {{ gsxx }}</div>
            </div>
            <div style="display: flex; justify-content: center; gap: 10px;font-size: 25px;color: azure;">
                <div style="  display: flex;flex-direction: column;margin-bottom: 10px;">WeChat<img
                        src="../assets/weixin.jpg" style="width: 150px;margin-top: 10px"></div>
                <div style="  display: flex;flex-direction: column;">Whats App <img src="../assets/whatapp.png"
                        style="width:150px;margin-top: 10px;font-size: 20px;"></div>
            </div>
            <div style="font-size: 18px; color:azure;padding-bottom: 20px;padding-top: 10px;">Email :2294742947@qq.com
            </div>
            <div
                style="text-align: center; width: 100%;font-size: 18px; line-height: 60px; color: #ffffff; height: 60px;">
                {{ copyright }}
            </div>
        </div>



    </div>
</template>

<script>
import axios from 'axios';
import { setProductInfo, getProductInfo } from '../utils/auth';
export default {
    name: 'App',
    data() {
        return {
            gsxx: `ShunMing International`,
            copyright: 'Copyright© 2024 by Henan Shunming Electronic Information Technology Co., Ltd. all rights reserved.',
            activeDropdown: null
        }
    },
    methods: {
        showDropdown(dropdownName) {
            this.activeDropdown = dropdownName;
        },
        hideDropdown(dropdownName) {
            if (this.activeDropdown === dropdownName) {
                this.activeDropdown = null;
            }
        },
        handlerClick(val, params) {
            let link = "/";
            if (val) link += val;
            this.$router.push({ path: link, query: params });
        },
        fetchData() {
            axios.post('website/website', { actionType: 'categoryProductQuery' })
                .then(response => {
                    let result = response.data;

                    if (result.success) {
                        setProductInfo(result.data);
                        this.productInfo = result.data;
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    },
    mounted() {
        this.fetchData();
    }
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

.top-container {
    width: 100%;
    height: 90px;
    background-color: rgb(77 77 77 / 66%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}

.router-view-container {
    position: relative;
    z-index: 1;
}

.nav-item {
    margin: 0px 20px;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
}

.nav-link {
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    height: 50px;
    display: flex;
    align-items: center;
}

.nav-item::after {
    content: '';
    height: 4px;
    background-color: transparent;
    width: 120%;
    position: absolute;
    bottom: -10px;
    left: -10%;
    transition: background-color 0.3s, bottom 0.3s;
}

.nav-item:hover::after {}

.nav-item:hover .nav-link {
    color: rgb(110, 161, 237);
}

.dropdown-content {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: rgb(77 77 77 / 66%);
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1001;
    top: 100%;
    left: 0;
}

.dropdown-content a {
    color: rgb(239, 239, 239);
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

.dropdown-content a:hover {
    background-color: #6a6a6a80;
}
</style>