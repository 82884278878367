<template>
    <div class="product-detail-view ">
        <div class="navigation-toolbar">
            <img src="@/assets/主页.png" style="width: 15px;height: auto;" />
            <span style="font-size: 15px;margin-left:20px;margin-right: 5px;cursor: pointer;"
                @click="change()">Home</span>
            <span style="font-size: 15px;margin-left:5px;margin-right: 5px;cursor: pointer;">/</span>
            <span style="font-size: 15px;margin-left:5px;margin-right: 5px;cursor: pointer;"
                @click="change('products')">Product</span>
            <span style="font-size: 15px;margin-left:5px;margin-right: 5px;cursor: pointer;">/</span>
            <span style="font-size: 15px;margin-left:5px;margin-right: 5px;cursor: pointer;"
                @click="change('products', categoryId)">{{
                    categoryEnName }}</span>
        </div>
    </div>
    <div>
        <div class="container">
            <div class="left">
                <div class="image-gallery">
                    <!-- 上方显示大图 -->
                    <div class="main-image">
                        <img :src="currentImage" alt="Main Image" />
                    </div>

                    <!-- 下方显示预览图 -->
                    <div class="thumbnails">
                        <div v-for="(image, index) in productData.images" :key="index"
                            :class="['thumbnail', { active: currentIndex === index }]" @click="changeImage(index)">
                            <img :src="image" alt="Thumbnail" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="right">
                <div class="product-en-name">{{ productData.product_en_name }}</div>
                <div class="product-remark">{{ productData.remark }}</div>
                <div class="product-detail">{{ productData.detail }}</div>
            </div>
        </div>



    </div>


</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            categoryId: null,
            categoryEnName: null,
            currentIndex: 0,
            productData: {
                images: []
            }
        }
    },
    computed: {
        currentImage() {
            return this.productData.images[this.currentIndex];
        },
    },
    methods: {
        changeImage(index) {
            this.currentIndex = index;
        },
        change(val, categoryId) {
            let link = "/";
            if (val) link += val;
            console.log(1);
            let query = {}
            if (categoryId) query.categoryId = categoryId;
            this.$router.push({ path: link, query: query });
        }
    },
    mounted() {
        console.log(this.$route.query);
        console.log(this.$route.query.productEnName);
        this.categoryId = this.$route.query.categoryId;
        this.categoryEnName = this.$route.query.categoryEnName;
        axios.post('website/website', { actionType: 'productDetailQuery', productId: this.$route.query.productId })
            .then(response => {
                let result = response.data;
                if (result.success) {
                    this.productData = result.data;
                    console.log(this.productData)
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });

    }
}
</script>
<style scoped>
.product-detail-view {
    margin-top: 90px;
    height: 40px;
    background-color: #fff;
    text-align: left;
    color: #6c6c6c;
    display: flex;
    flex-direction: column;
    /* 纵向排列子元素 */
    justify-content: center;
    /* 垂直方向居中 */

}

.navigation-toolbar {
    display: flex;
    align-items: center;
}

.product-detail {
    overflow-x: auto;
    /* 启用横向滚动 */
    text-align: left;
    font-size: 16px;
    color: #9b9b9b;
    font-family: serif;
    font-weight: 600;
    margin: 10px;
    height: 400px;
    line-height: 20px;
    padding-top: 20px;
}

.container {
    margin: 50px 3%;
    background-color: #F1F1F1;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.left {
    width: 100%;
    background-color: white;
    /* 用于演示 */
}

.right {
    padding: 10px;
    background-color: white;

    /* 用于演示 */
    .product-en-name {
        height: 40px;
        padding-top: 50px;
        text-align: left;
        font-size: x-large;
        color: #4bc3ff;
        font-family: fantasy;
    }

    .product-remark {
        text-align: left;
        height: 80px;
        font-size: 15px;
        color: #939393;
        line-height: 1.5;
        border-bottom: 2px #d3d3d3 solid;
    }
}

.image-gallery {
    width: 100%;
    margin: 0 auto;
}

.main-image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.main-image img {
    width: 90%;
    margin-top: 10px;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
}

.thumbnails {
    display: flex;
    padding: 0px 10px;

}

.thumbnail {
    cursor: pointer;
    border: 2px solid #d2d2d270;
    ;
    transition: border-color 0.3s ease;
    margin: 0px 5px;
}

.thumbnail img {
    width: 100%;
    height: 60px;
    object-fit: cover;
}

.thumbnail.active {
    border-color: #42b983;
    /* 激活时的边框颜色 */
}
</style>