<template>
    <div class="iot-view-style">
        <div style="position: relative;">
            <div><img style="width: 100%;"
                    src="https://qiniu.shunmingelectronic.com/guanwang/product/IST_28936_07550.jpg" /></div>
            <div class="home-page-text">
                <div style="padding: 0px 20%;text-align: left;    color: #fff0d9;">We provide you with advanced flow
                    meter products and integrated IoT solutions, making your data collection and device configuration
                    more convenient.</div>
            </div>
        </div>

        <div class="big-title">Smart Monitoring
            Real-Time Control</div>
        <div class="container">
            <div class="left">
                <img style="width: 100%;" src="https://qiniu.shunmingelectronic.com/guanwang/product/shyetongji.png" />
            </div>
            <div class="right">
                <div style=" margin-top: 150px; font-size: 30px; color: #1296db; font-family: math;text-align: left;">
                    Our unique IoT system
                    utilizes 4G modules to enable efficient transmission of flow meter data, allowing you to monitor
                    your device
                    status and data in real-time, anytime, anywhere.
                </div>
            </div>
        </div>
        <div class="container">
            <div class="left">
                <div style=" margin-top: 150px; font-size: 30px; color: #1296db; font-family: math;text-align: left;">
                    The IoT platform
            supports multidimensional data analysis,
            automatically generating detailed reports and graphical pages to help you easily make data-driven decisions.
                </div>
            </div>
            <div class="right">
                <img style="width: 100%;" src="https://qiniu.shunmingelectronic.com/guanwang/product/TONGJI.png" />
            </div>
        </div>
        <div><img style="width: 60%;"
                src="https://qiniu.shunmingelectronic.com/guanwang/product/%E5%B1%8F%E5%B9%95zongji.png" /></div>
        <div class="big-title" style="margin-top: 150px;">Digital Twin Technology Personalized Scenario Design</div>


        <div class="container" style="margin-bottom: 10px;">
            <div class="left">
                <img style="width: 100%;" src="https://qiniu.shunmingelectronic.com/guanwang/luansheng1.png"  />
            </div>
            <div class="right">
                <img style="width: 100%;" src="https://qiniu.shunmingelectronic.com/guanwang/luansheng2.png"  />
            </div>
        </div>
        <div class="small-title" style="padding-top: 0px;">We have introduced advanced Digital Twin technology, enabling highly personalized
            scenario design for our
            customers. By precisely mapping real-world data, you can intuitively simulate and optimize equipment
            operation in a virtual environment, customizing solutions that meet your unique needs. This technology not
            only enhances operational convenience but also helps you maximize equipment performance and efficiency.
        </div>
        <div class="big-title" style="margin-top: 150px;">Flexible and Efficient Customer Support</div>
        <div class="small-title">To better support distributors, our platform offers distributor privilege accounts.
            Distributors can not only assign accounts to end users, enabling flexible permission management, but also
            customize the platform's company logo and title according to their business needs, enhancing brand
            recognition and customer satisfaction.
        </div>
        <div class="big-title" style="margin-top: 150px;">Platform Key Features</div>
        <div class="choose-style">
            <div><img src="../assets/jiankong.png" class="image-style"></div>
            <div class="choose-text">Real-Time Monitoring: Continuously monitor device operation data and status to
                ensure safety and efficient performance.</div>
        </div>
        <div class="choose-style">
            <div><img src="../assets/tongjitu.png" class="image-style"></div>
            <div class="choose-text">Multidimensional Statistics: Provide comprehensive flow data analysis to help you
                gain deep insights into flow trends and usage.</div>
        </div>

        <div class="choose-style">
            <div><img src="../assets/baogao.png" class="image-style"></div>
            <div class="choose-text">Report Generation: Automatically generate detailed reports and graphical pages,
                supporting data visualization and business optimization.</div>
        </div>
        <div class="choose-style">
            <div><img src="../assets/changjing.png" class="image-style"></div>
            <div class="choose-text">Personalized Scenario Design: Utilize Digital Twin technology to create customized
                solutions that meet customer needs, optimizing device operation and management.</div>
        </div>
        <div style="margin: 100px 20px; display: flex; justify-content: center;" ref="videoContainer">
            <video ref="videoElement" style="width: 60%;" controls muted>
                <source src="https://qiniu.shunmingelectronic.com/guanwang/mymeter.mp4" type="video/mp4">
                Your browser does not support the video tag.
            </video>
        </div>

    </div>
</template>
<script>
export default {
    data() {
        return {
            hasUserInteracted: false,
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleUserInteraction);
        this.createIntersectionObserver();
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleUserInteraction);
    },
    methods: {
        handleUserInteraction() {
            this.hasUserInteracted = true;
        },
        createIntersectionObserver() {
            const videoElement = this.$refs.videoElement;

            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting && this.hasUserInteracted) {
                        videoElement.play().catch(error => {
                            console.log("Auto-play was prevented: ", error);
                        });
                    } else {
                        videoElement.pause();
                    }
                });
            }, {
                threshold: 0.5
            });

            observer.observe(videoElement);
        }
    }
};</script>
<style scoped>
.iot-view-style {
    .home-page-text {
        position: absolute;
        top: 40%;
        width: 100%;
        color: white;
        font-size: 36px;
        font-weight: bold;
        text-align: center
    }

    .big-title {
        font-size: 60px;
        margin-top: 160px;
        font-family: initial;
        font-weight: bold;
        color: #1296db;
    }

    .small-title {
        padding: 80px 280px;
        font-size: 2rem;
        color: #1296db;;
        text-align: left;
    }

    .container {
        display: flex;
        margin: 100px 10%;
        height: 600px;
        background-color: #F1F1F1;
    }

    .left {
        width: 50%;
        background-color: white;
        /* 用于演示 */
    }

    .right {
        padding-left: 150px;
        width: 50%;
        background-color: white;
    }

    .choose-style {
        text-align: left;
        height: 150px;
        margin: 80px 250px 80px 300px;
        display: flex;

        /* 启用 flexbox 布局 */
        .image-style {
            width: auto;
            height: 100%;
        }

        .choose-text {
            align-items: center;
            font-size: 30px;
            display: flex;
            margin-left: 100px;
            color: #1296db;
        }
    }
}
</style>