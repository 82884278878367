<template>
  <div>
    <div>
      <img style="width: 100%;" src="https://qiniu.shunmingelectronic.com/guanwang/product/lianxiwomen.jpg" />
    </div>
    <div class="home-page-text">
      <div style="padding: 0px 20%;   color: #09bfff;">
        Contact us
      </div>
    </div>
    <div style="padding: 50px 15%;">
      <div style="margin-bottom: 80px; color: rgb(134 134 134); text-align: center;">
        <div style="font-size: 20px;margin-top: 20px; font-weight: 700;text-align: left;">
          <div style="margin-bottom: 10px;color: rgb(94 196 231);">Company Name:</div> <span>Henan Shunming Electronic
            Information Technology Co., Ltd.</span>
        </div>
        <div style="font-size: 18px;margin-top: 40px; font-weight: 700;text-align: left;">
          <div style="margin-bottom: 10px;color: rgb(94 196 231);">Company Address:</div> <span>No. 1 Fenghua Road,
            High-Tech Development Zone, Luoyang Area, China (Henan) Pilot Free Trade Zone, Henan, China</span>
        </div>
        <div style="font-size: 18px;margin-top: 40px; font-weight: 700;text-align: left;">
          <div style="margin-bottom: 10px;color: rgb(94 196 231);">Contact Phone Number:</div> <span>
            +86 15038549758</span>
        </div>
        <div style="font-size: 18px;margin-top: 40px; font-weight: 700;text-align: left;">
          <div style="margin-bottom: 10px;color: rgb(94 196 231);">Email Us:</div> <span>
            2294742947@qq.com</span>
        </div>
      </div>
      <div style=" display: flex; /* 启用 Flexbox 布局 */ justify-content: center; /* 水平居中 */ margin-top: 20px;">
        <div style="width: 40%;">
          <div style="font-size: 20px;margin-bottom: 30px;">Wechat</div>
          <div><img src="../../assets/weixin.jpg" width="90%" /></div>
        </div>
        <div style="width: 40%;">
          <div style="font-size: 20px;margin-bottom: 30px;">WhatsApp</div>
          <div><img src="../../assets/whatapp.png" width="90%" /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactView'
}
</script>

<style scoped>
.home-page-text {
  position: absolute;
  top: 8%;
  width: 100%;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  text-align: center
}
</style>